@use '@cloudscape-design/design-tokens/index' as awsui;

.sortableItem {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  padding-left: 1em;
  background-color: #fff;
  box-sizing: border-box;
  list-style: none;
  color: #333;
  font-weight: 400;
  font-size: 1rem;
  font-family: sans-serif;
  border-block-start: var(--border-divider-list-width-27y3k5, 1px) solid var(--color-border-divider-secondary-um17yo, #e9ebed);
}

.dragHandle {
  display: flex;
  width: 12px;
  padding: 15px;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.dragHandle:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.dragHandle:focus-visible {
  box-shadow: 0 0px 0px 2px #4c9ffe;
}

.dragHandle svg {
  flex: 0 0 auto;
  margin: auto;
  height: 100%;
  overflow: visible;
  fill: #919eab;
}


