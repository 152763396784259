@use '~@cloudscape-design/design-tokens' as cs;
@use '@cloudscape-design/design-tokens/index' as awsui;


[data-style="grid-container"] {
  flex:1;
  > div > div {
    display: flex;
  }
}
.boxItem {
  border: 1px solid cs.$color-border-control-default;
  border-radius: cs.$border-radius-token;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: cs.$space-scaled-xs;
  flex: 1 100%;
  &.success {
    background-color: awsui.$color-charts-status-positive;
  }

  &.warning {
    background-color: awsui.$color-charts-status-medium;
  }

  &.error {
    background-color: awsui.$color-charts-status-high;
  }
  > div {
    padding: cs.$space-scaled-s cs.$space-scaled-xl ;
  }
  > div:first-of-type {
    flex-grow: 1;
    border-top-left-radius: cs.$border-radius-token;
    border-top-right-radius: cs.$border-radius-token;
    border-bottom: 1px solid cs.$color-border-control-default;
    background-color: cs.$color-background-button-normal-active;
    
  }
}

