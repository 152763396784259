#top-nav {
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1002;
}

#footer {
    position: fixed;    /* Fixes the footer at the bottom */
    bottom: 0;          /* Positions the footer at the bottom of the viewport */
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #00142b;
    border-top: 1px solid #e0e0e0;
    font-size: small;
    color: #fff;
    z-index: 1001;      /* Ensures footer is above the content but below the top navigation */
}

#app-content {
    padding-bottom: 30px;
}

.footer-left, .footer-right {
    flex: 1;
}

.footer-right {
    text-align: right;
}