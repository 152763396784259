// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

@use '~@cloudscape-design/design-tokens' as cs;

.layout {
  display: flex;
  align-items: center;
  gap: cs.$space-scaled-m;
}

.palleteItem {

  border: 1px solid cs.$color-border-control-default;
  border-radius: cs.$border-radius-container;
  padding: cs.$space-scaled-m;
  cursor: grab;

}