$row-height: 100px;
$row-padding: 15px;
$grid-columns: 12;

:export {
  rowHeight: $row-height;
  rowPadding: $row-padding;
  gridColumns: $grid-columns;
}

.isInMotion {
  min-height: calc((2 * $row-height) + (2 * $row-padding));
  background: #bab5b5;
  background: conic-gradient(from 90deg at $row-padding $row-padding, #cccccc41 90deg, #fff 0) 0 0 /
    calc(((100% - $row-padding) / $grid-columns)) calc($row-height + $row-padding);
}

.gridItemContainer {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: white;
  overflow: hidden;

  > section {
    display: flex;
    flex-direction: column;
    inline-size: 100%;
    block-size: 100%;
  }

  .gridWidgetHeader {
    display: flex;
    padding: 5px 15px 0;
  }
  .gridContent {
    height: calc(100% - 40px);
    padding: 0 15px 15px;
    flex:1 1;
    display:flex;
    justify-content: center;
  }

  header {
    font-weight: 900;
    font-size: 3em;
  }
}

.dragger {
  padding-right: 0.5em;
  display: flex;
  align-content: center;
  align-items: center;
  cursor: grab;
}
