.header#header-navigation {
  display: flex;
  align-items: center;
  padding: 1em;
  background-color: rgb(9, 21, 64);

  a[class^='awsui_link'] {
    color: #ffffff;
    font-size: 1.5em;
    padding-left: 1em;
  }

  .filler {
    flex-grow: 10;
  }

  > :nth-child(3) {
    position: relative;
    padding: 0.5em;
    &::after {
      display: block;
      position: absolute;
      content: '';
      width: 1px;
      right: 0;
      top: var(--space-s-34lx8l, 12px);
      bottom: var(--space-s-34lx8l, 12px);
      background: var(--color-border-divider-default-et9j7s, #e9ebed);
    }
  }

  div > [class^='awsui_button'],
  [class^='awsui_button-dropdown'] [class^='awsui_root'] [class^='awsui_dropdown-trigger'] > button,
  [class^='awsui_root'] [class^='awsui_root'] button[class^='awsui_button-trigger'] {
    background: transparent;
    color: #ffffff;
    border: 0;
    > span {
      color: #ffffff;
    }
  }

  > div > [class^='awsui_button'] {
    padding: 0.5em;
  }
}

@media (max-width: 768px) {
  .profile-name {
    display: none;
  }
}
