@use '@cloudscape-design/design-tokens/index' as awsui;

.singleMetricWidget.singleMetricWidget { // intentional to increase specificity
  > section {
    padding:0;
  }
  .singleMetric {
    flex: 1 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    container-type: inline-size;

    &.success {
      background-color: awsui.$color-charts-status-positive;
    }

    &.warning {
      background-color: awsui.$color-charts-status-medium;
    }

    &.error {
      background-color: awsui.$color-charts-status-high;
    }

    .metric {
      font-size: 6em;
    }
  }
}
@container (inline-size > 600px) {
  .singleMetricWidget.singleMetricWidget { // intentional to increase specificity
    .singleMetric {
      .metric {
        font-size: 12em;
      }
    }
  }
}
