body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #00142b;
}


[data-amplify-router] {
    border-radius: 35px;
}

[data-amplify-authenticator] {
    height: 100vh;
    font-weight: 500;
}

.amplify-button[data-variation='primary'] {
    background: linear-gradient(
            to right,
            var(--amplify-colors-blue-60),
            var(--amplify-colors-blue-60)
    );
}

/*Main loading page, after auth, before app*/
.mainLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* 100% of the viewport height */
}

