.trendContainer {
  margin-left: '0px';
  display: 'flex';
  flex-direction: 'row';
  align-items: 'center';

  .trendBody {
    font-size: '2em';
    margin-left: '0px';
    display: 'flex';
    align-items: 'baseline';

    .trendDirection {
      font-size: '0.6em';
      margin-left: '5px';
    }
  }
}
