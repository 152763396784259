.inlineFormField {
    display: flex;

    >div:first-of-type {
        margin-right: 7rem;
    }

    >div:last-of-type {
        flex: 1;
    }
}