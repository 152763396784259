@use '@cloudscape-design/design-tokens/index' as awsui;
.agentStatusBody > section {
  container-type: inline-size;
}
.agentStatusContainer {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-auto-rows: max-content;
  gap: 5px;
  overflow-y:auto;

  .agentItem {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid awsui.$color-border-divider-default;
    overflow: hidden;

    .agentUsername {
      flex: 1 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 700;
      text-align: center;
    }

    .agentStatus {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      justify-items: center;
    }
  }
}
.userIcon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
  flex-basis:100%;
}

.ROUTABLE {
  background-color: awsui.$color-charts-status-positive;
}
.ON_CALL {
  background-color: awsui.$color-charts-status-info;
}
.ACW {
  background-color: awsui.$color-charts-purple-400;
}

.CUSTOM,
.OFFLINE {
  background-color: awsui.$color-charts-status-medium;
}

.ERROR {
  background-color: awsui.$color-charts-status-high;
}

@container (inline-size > 400px) {
  .agentStatusContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@container (inline-size > 600px) {
  .agentStatusContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}
@container (inline-size > 800px) {
  .agentStatusContainer {
    grid-template-columns: repeat(6, 1fr);
  }
}
@container (inline-size > 1000px) {
  .agentStatusContainer {
    grid-template-columns: repeat(8, 1fr);
  }
}
@container (inline-size > 1200px) {
  .agentStatusContainer {
    grid-template-columns: repeat(10, 1fr);
  }
}
@container (inline-size > 1400px) {
  .agentStatusContainer {
    grid-template-columns: repeat(12, 1fr);
  }
}
@container (inline-size > 1600px) {
  .agentStatusContainer {
    grid-template-columns: repeat(14, 1fr);
  }
}
@container (inline-size > 1800px) {
  .agentStatusContainer {
    grid-template-columns: repeat(16, 1fr);
  }
}
@container (inline-size > 2000px) {
  .agentStatusContainer {
    grid-template-columns: repeat(18, 1fr);
  }
}
@container (inline-size > 2200px) {
  .agentStatusContainer {
    grid-template-columns: repeat(20, 1fr);
  }
}
@container (inline-size > 2400px) {
  .agentStatusContainer {
    grid-template-columns: repeat(22, 1fr);
  }
}
.sortableListItem {
  flex: 1;
  h4 {
    margin: 1em 0 0;
  }
  p {
    margin: 0 0 1em;
    color: awsui.$color-border-divider-default;
  }
}
