// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
.staticWidget {
  height: 100%;
}

.quicksightFrame {
  width: 100%;
  height: 100%;
  border: none;
  overflow: hidden;
}

.quicksightFramePlaceHolder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
