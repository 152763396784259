@use '@cloudscape-design/design-tokens/index' as awsui;

.header {
    padding: awsui.$space-scaled-xl 0 0 awsui.$space-scaled-xl;
    display: flex;
    justify-content: 'space-between';
    align-items: 'center';
}
.ssoLoginContainer {
    flex-direction: column;
    width:90%;
    justify-content:center;
    margin:0 auto 1em;
}